<template>
  <div
    class="banner"
    :style="bgStyle"
  >
    <h4 class="banner__title">
      Не нашли объект культуры?
    </h4>

    <a
      href="#"
      class="banner__btn briscola inline-flex align-center mt-2"
      @click.prevent="$emit('openModal', $event)"
    >
      <div class="briscola">Добавить</div>
      <r-icon
        class="banner__icon ml-1"
        style="margin-top: 2px"
        icon="arrow-right"
        size="16"
        fill="casino"
      />
    </a>
  </div>
</template>

<script>

export default {
  computed: {
    bgStyle() {
      return {
        background: `url(${window.__CONFIG__.storage}/${window.__CONFIG__.appFolder}/bg_banner.png) no-repeat
      right, var(--rir-intouchables)`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .rir-modal__overlay{
  pointer-events: none;
}
.banner {
  // background: url('https://storage.rosatom.city/platform-test/vue-culture/bg_banner.png') no-repeat
  //     right,
  //   var(--rir-intouchables);
  min-height: 80px;
  padding: 16px 24px;
  border-radius: 16px;
  margin-top: 12px;
  text-align: left;

  &__title {
    font-weight: bold;
    color: var(--rir-extraction);
    font-size: 20px;
    line-height: 24px;
  }
  &__btn {
    color: var(--rir-casino);
  }
  &__icon {
    transform: scale(0.7);
  }
}

@media screen and (max-width: 600px) {
  .banner {
    background-position-x: calc(100% + 60px);
    &__title {
      font-size: 16px;
      line-height: 20px;
      max-width: 180px;
    }
  }
}
</style>
