<template>
  <div>
    <tag-carousel
      :items="objectTypes"
      @select="onChangeType"
    />
    <loader v-if="isLoading" />
    <div
      v-else
      class="culture mt-8"
    >
      <div class="culture__cards">
        <div
          v-for="item in objects"
          :key="item.id"
          class="flex"
        >
          <router-link
            :to="`/${item.id}`"
            class="size"
          >
            <culture-card
              :name="item.name"
              :address="item.address"
              :img="getPreviewImg(item)"
              icon="star"
            >
              <span v-if="item.rating">{{ item.rating.substr(0, 3) }}</span>
            </culture-card>
          </router-link>
          <div
            :key="count"
            @click="operateFavoriteCard(item.id, item.name)"
          >
            <r-tooltip

              :title="!favoriteIds[item.id] ? 'в избранное' : 'Удалить из избранного'"
              position="top-center"
              align-title="end"
              max-width="200px"
            >
              <template #activator>
                <r-icon
                  class="mt-1 ml-auto pointer"
                  :icon="!favoriteIds[item.id] ? 'favorite-add' : 'favorite-add-active'"
                  size="16"
                  fill="rocky"
                />
              </template>
            </r-tooltip>
          </div>
        </div>
      </div>
      <div class="culture__map-block">
        <div class="culture__map-cont">
          <div
            :class="['culture__map', { 'culture__map--collapsed': isMapCollapsed }]"
            ref="map"
          >
            <rir-map
              v-if="isShowMap"
              v-model="search"
              search
            >
              <!-- <div :key="objects.length"> -->
              <ymap-marker
                v-for="marker in objects"
                :key="`${marker.id}_${search}`"
                :coords="[`${marker.lat}`, `${marker.lng}`]"
                :marker-id="marker.id"
                :icon="$markerIcon()"
                :options="{ hideIconOnBalloonOpen: false }"
                cluster-name="main"
                @click="onMarkerClick(marker.id)"
                @balloonopen="bindListener($event, marker.id, favoriteIds[marker.id], marker.name)"
                @balloonclose="unbindListener($event, marker.id, favoriteIds[marker.id], marker.name)"
              >
                <balloon-card
                  slot="balloon"
                  :title="marker.name"
                  :address="marker.address"
                  :img="marker.images[0] ? marker.images[0].url : null"
                  :data-id="marker.id"
                  :id="marker.id"
                  :favorite="favoriteIds[marker.id]"
                >
                  <div />
                  <!-- <div class="flex mt-3">
                    <rir-icon class="mr-2" icon="geopoint" />
                    <span class="briscola text-rocky">Построить маршрут</span>
                  </div> -->
                </balloon-card>
              </ymap-marker>
              <div v-if="objects && objects.length == 0">
                <ymap-marker
                  :coords="coordinates"
                  :marker-id="'marker'"
                  :options="{
                    hideIconOnBalloonOpen: false,
                    draggable: false
                  }"
                  :icon="$markerIcon()"
                />
              </div>
              <!-- </div> -->
            </rir-map>

            <button
              class="culture__map-collapse-btn feta"
              @click="isMapCollapsed = !isMapCollapsed"
            >
              <span> {{ isMapCollapsed ? 'Развернуть' : 'Свернуть' }} карту </span>
            </button>
          </div>
          <culture-add-banner @openModal="openModal" class="info__banner mb-8" />
        </div>
      </div>
    </div>
    <r-modal
      ref="modal"
      close-icon
    />
  </div>
</template>

<script>
import CultureCard from '@/components/CultureCard.vue';
import CultureAddBanner from '@/components/CultureAddBanner.vue';
import axios from 'axios';
import Loader from '@/components/Loader.vue';
import Api from '@/api/PlaceApi';
import TagCarousel from '../components/TagCarousel.vue';
import RirMap from '../components/RirMap.vue';
import BalloonCard from '../components/BalloonCard.vue';
import ModalAddObject from "@/components/ModalAddObject.vue";

export default {
  name: 'Home',
  components: {
    Loader,
    CultureCard,
    CultureAddBanner,
    TagCarousel,
    RirMap,
    BalloonCard
  },

  data() {
    return {
      files: [],
      selectedCategory: '0',
      isMapCollapsed: true,
      searchText: null,
      timeout: null,
      isShowMap: false,
      selectedMarkerId: null,
      coordinates: [47.5259165, 42.1899268],
      userId: null,
      favorite: [],
      favoriteIds: [],
      favoriteCount: 0,
      count: 0,
      selectedMarkerTitle: '',
      eventId: null,
      onEvent: false
    };
  },

  computed: {
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchText = val;
        }, 420);
      }
    },

    objects() {
      const _this = this;
      let allObjects = this.$store.state.objects
        && this.$store.state.objects.filter(el => el.id && el.lat);
      if (!allObjects?.length) return [];

      // TODO: fix для текущей базы объектов. Сейчас images - массив строк, надо [{filename: string, url: string}]
      allObjects = allObjects.map(el => {
        _this.favoriteIds[el.id] = false;
        if (_this.favorite.fav != undefined) {
          const fav = _this.favorite.fav.filter(els => el.id == els.id);
          if (fav.length > 0) {
            _this.favoriteIds[el.id] = true;
          }
        }
        return {
          sort: _this.favoriteIds[el.id],
          ...el,
          images:
          typeof el.images[0] === 'string'
            ? el.images.map(img => ({ filename: 'Без названия', url: img }))
            : el.images
        };
      });
      // allObjects.forEach(element => {
      //   console.log(element.name, element.lat, element.lng)
      // });

      if (allObjects && this.selectedCategory !== '0' && this.selectedCategory !== 'all') {
        allObjects = allObjects.filter(el => String(el.typeId) === this.selectedCategory);
      }
      if (allObjects && this.selectedCategory == 'all') {
        allObjects = allObjects.filter(el => (el?.authorId ? String(el.authorId.replace(/-/g, '')) === this.userId : false));
      }
      if (this.searchText) {
        allObjects = allObjects.filter(el => (el?.address
          ? this.onAddress(el.address)
          : false));
      }
      allObjects.sort((a, b) => {
        const title1 = a.name ? a.name.toLowerCase() : 1;
        const title2 = b.name ? b.name.toLowerCase() : 2;
        if (title1 < title2) return -1;
        if (title1 > title2) return 1;
      });
      if (allObjects.length == 0 && this.searchText) {
        this.onCoordinates(this.searchText);
      }
      allObjects = allObjects.sort((a, b) => b.sort - a.sort);

      return allObjects;
    },

    objectTypes() {
      const types = this.$store.state.types
        ? this.$store.state.types.map((el, index) => ({
          ...el,
          title: el.type,
          active: false
        }))
        : [];
      types.length && types.sort((a, b) => {
        const title1 = a.title.toLowerCase();
        const title2 = b.title.toLowerCase();
        if (title1 < title2) return -1;
        if (title1 > title2) return 1;
      }).unshift({ title: 'Все', active: true, id: '0' }, { title: 'Мои объекты культуры', active: false, id: 'all' });
      return types;
    },

    isLoading() {
      return this.$store.state.isObjectLoading;
    }
  },

  activated() {
    this.isShowMap = true;
  },

  deactivated() {
    this.isShowMap = false;
  },

  async mounted() {
    const api = new Api();
    const res = await api.getUserInfo();
    if(res?.userId) {
      this.userId = res.userId.replace(/-/g, '');
    }
    this.showMap = true;
    !this.objectTypes?.length && this.$store.dispatch('loadTypes');
    this.loadObjects();
    this.favorite = await api.getFavorite();
  },
  methods: {
    async openModal() {
      const api = new Api();
      const res = await api.getUserInfo();
      if (res?.userId > 0 && res?.fio !== '') {
        await this.$refs.modal.openModal(ModalAddObject, {
          authorName: res.fio,
          authorEmail: res?.email || ''
        });
      } else {
        window.dispatchEvent(
          new CustomEvent('openModal', {
            detail: {
              name: 'LoginModal',
              props: {
                title: 'Пожалуйста, авторизуйтесь',
                text: 'Добавление объектов доступно только авторизованным пользователям'
              }
            }
          })
        );
      }
    },
    operateFavorite() {
      this.onEvent = true;
      const api = new Api();
      api.setFavorite(this.selectedMarkerId, this.selectedMarkerTitle);
      this.favoriteIds[this.selectedMarkerId] = !this.favoriteIds[this.selectedMarkerId];
      this.count++;
    },
    operateFavoriteCard(id, title) {
      const api = new Api();
      api.setFavorite(id, title);
      this.favoriteIds[id] = !this.favoriteIds[id];
      this.count++;
    },
    onCoordinates(item) {
      if (item) {
        axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=f8217e42-9b86-4033-8411-a7bf4f8d6835&geocode=Тарко-Сале ${item.trim()}&format=json&lang=ru_RU&kind=house`)
          .then(result => {
            const geo = result.data.response.GeoObjectCollection.featureMember?.[0]?.GeoObject;

            if (geo) {
              this.coordinates = geo.Point?.pos?.split(' ').reverse();
              console.log(this.coordinates);
            }
          });
      }
    },
    onAddress(address) {
      let len = 0;
      const arr = this.searchText.toLowerCase().replace(/ +/g, ' ').trim().split(' ');
      arr.forEach(item => {
        if (address.toLowerCase().indexOf(item) >= 0) {
          len++;
        }
      });
      return address
        ? len == arr.length
        : false;
    },
    getPreviewImg(item) {
      if (item.images?.length && item.images[0]?.url) {
        return item.images[0].url;
        // TODO: Старый формат данных, удалить после переноса на новую базу
      } if (item.images?.length) {
        return item.images[0];
      }
      return null;
    },

    loadObjects(typeId) {
      this.$store.dispatch('loadObjects', typeId);
    },

    onChangeType(type) {
      if (type.id !== this.selectedCategory) {
        this.selectedCategory = type.id;
      }
    },

    onBalloonClick() {
      console.log('dwdw', this.selectedMarkerId);
      if (!this.selectedMarkerId) return;
      if (!this.onEvent) {
        this.$router.push({
          path: `/${this.selectedMarkerId}`
        });
      }
      this.onEvent = false;
      this.favoriteCount++;
    },
    iconBalloon(link) {
      if (this.favoriteIds[this.selectedMarkerId]) {
        link.innerHTML = `<svg width="16" class="ml-auto pointer" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 3.01448C2 1.34963 3.34315 0 5
                            0H11C12.6569 0 14 1.34963 14 3.01448V13.9866C14 15.6717
                            12.0601 16.6085 10.7506 15.5558L8 13.3447L5.24939 15.5558C3.93986 16.6085 2 15.6717 2 13.9866V3.01448Z" fill="#3D75E4"/>
                          </svg>`;
      } else {
        link.innerHTML = `<svg width="16" class="ml-auto pointer" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 2.00966C4.44772 2.00966 4 2.45953
                            4 3.01448V13.9866L6.75061 11.7755C7.48105 11.1883
                            8.51895 11.1883 9.24939 11.7755L12 13.9866V3.01448C12 2.45953 11.5523 2.00966 11
                            2.00966H5ZM2 3.01448C2 1.34963 3.34315 0 5 0H11C12.6569
                            0 14 1.34963 14 3.01448V13.9866C14 15.6717 12.0601 16.6085 10.7506 15.5558L8
                            13.3447L5.24939 15.5558C3.93986 16.6085 2 15.6717 2 13.9866V3.01448Z" fill="#3D75E4"/>
                          </svg>`;
      }
    },
    // Обрабочики для Balloon'a карты
    bindListener(event, id, value, title) {
      console.log('222', this.selectedMarkerId);
      if (!this.selectedMarkerId) return;
      this.selectedMarkerTitle = title;
      this.eventId = event;
      const link = this.$refs.map.querySelector(
        `.link-balloon[link-id="${this.selectedMarkerId}"]`
      );
      this.iconBalloon(link);
      link.addEventListener('click', this.operateFavorite);

      const balloon = this.$refs.map.querySelector(
        `.r-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    },

    unbindListener(event, id, value, title) {
      console.log('222', this.selectedMarkerId);
      if (!this.selectedMarkerId) return;
      this.selectedMarkerTitle = title;
      this.eventId = event;
      const link = this.$refs.map.querySelector(
        `.link-balloon[link-id="${this.selectedMarkerId}"]`
      );
      this.iconBalloon(link);
      link.addEventListener('click', this.operateFavorite);
      const balloon = this.$refs.map.querySelector(
        `.r-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    },

    onMarkerClick(id) {
      this.selectedMarkerId = id;
    }
  }
};
</script>
<style lang="scss" scoped>
.size {
  width: 94%;
}
.culture {
  display: flex;
  justify-content: space-between;
  &__cards {
    width: 41.7%;
  }
  &__map-block{
    width: calc(58.3% - 32px);
    margin-left: 32px;
  }
  &__map-cont {
    position: sticky;
    position: -webkit-sticky;
    top: 32px;
    width: 100%;
    //width: calc(58.3% - 32px);
    //margin-left: 32px;
  }
  &__map {
    position: sticky;
    position: -webkit-sticky;
    display: flex;
    height: 556px;
    width: 100%;
    background-color: #ccc;
    border-radius: 24px;
    position: relative;
    transition: 0.4s all;
  }
  &__map-collapse-btn {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .culture {
    max-width: 100%;
    flex-direction: column-reverse;
    &__cards {
      width: 100%;
    }
    &__map-cont {
      position: relative;
      top: unset;
      background-color: #fff;
      height: unset;
      padding: 0;
      margin-left: unset;
      width: 100%;
    }
    &__map-block{
      width: 100%;
      margin-left: 0px;
    }
    &__map {
      height: 447px;
      margin-bottom: 32px;
    }
    &__map--collapsed {
      height: 112px;
    }
    &__map-collapse-btn {
      height: 40px;
      width: 174px;
      border-radius: 8px;
      padding: 10px 16px;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 87px);
      background-color: var(--rir-arrival);
      cursor: pointer;
      display: block;
      :first-child {
        color: var(--rir-godfather);
        border-bottom: 2px dotted #214eb052;
      }
    }
  }
}
r-link {
  font-family: riricon!important;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-flex;
}
@media screen and (min-width: 1024px) {
  .culture {
    &__input-search {
      display: none;
    }
  }
}
// @media screen and (min-width: 600px) {
//   .culture {
//     &__input-search {
//       position: absolute;
//       z-index: 10;
//       background-color: #fff;
//       box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);
//       top: 16px;
//       left: 16px;
//     }
//   }
// }

@media screen and (max-width: 599px) {
  .culture {
    &__map--collapsed {
      height: 144px;
    }
  }
}
.rir-favorite-add-active_16 { content: "\eb30"; font-size: 16px }
.rir-favorite-add_16 { content: "\eb30"; font-size: 16px }
</style>
