import Vue from 'vue';
import Vuex from 'vuex';
import PlaceApi from '@/api/PlaceApi';

const api = new PlaceApi();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    types: null,
    objects: null,
    selectedObject: null,
    isObjectLoading: null
  },
  mutations: {
    setTypes(state, types) {
      state.types = types;
    },
    setObjects(state, objects) {
      state.objects = objects;
    },
    setSelectedObject(state, object) {
      state.selectedObject = object;
    },
    setObjectLoading(state, status) {
      state.isObjectLoading = status;
    }
  },
  getters: {
    getTypes: () => state.objects.types,
    getObjectById: state => id => state.objects.find(el => el.id === id)
  },
  actions: {
    async selectObject(ctx, id) {
      if (!ctx.state.objects?.length) {
        const res = await api.getList({ readOnly: 1, type: 0, currentMainMenuNumber: 5 });
        ctx.commit('setObjects', res.all);
      }
      const selected = ctx.state.objects.find(el => el.id === id);
      ctx.commit('setSelectedObject', selected);
    },
    async loadObjects(ctx, typeId = 0) {
      ctx.commit('setObjectLoading', true);
      const res = await api.getList({ readOnly: 1, type: typeId, currentMainMenuNumber: 5 });
      ctx.commit('setObjects', res.all);
      ctx.commit('setObjectLoading', false);
    },
    async loadTypes(ctx) {
      const res = await api.getTypes();
      ctx.commit('setTypes', res.types);
    }
  },
  modules: {}
});
