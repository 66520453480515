import Vue from 'vue';
import VueRouter from 'vue-router';
import Culture from '../views/Culture.vue';
import CultureMain from '../views/CultureMain.vue';
import NotFoundPage from '../views/NotFoundPage';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Culture',
    component: Culture,
    children: [
      {
        // при совпадении пути с шаблоном /
        // в <router-view> компонента Culture будет показан CultureMain
        path: '',
        component: CultureMain
      },
      {
        // при совпадении пути с шаблоном /:id
        // в <router-view> компонента Culture будет показан CultureItem
        name: 'info',
        path: ':id',
        // component: CultureItem
        component: () => import(/* webpackChunkName: "item" */ '../views/CultureItem.vue')
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

export default router;
