<template>
  <div class="add-object">
    <div
      class="add-object__formData"
      v-show="!isSended"
    >
      <h2 class="camembert">
        Добавление объекта культуры
      </h2>
      <r-input
        class="mt-8"
        label="Автор"
        :value="authorName"
        readonly
      />
      <r-checkbox
        class="mt-3"
        title="Видно всем"
        v-model="formData.isShowAll"
      />
      <r-input
        class="mt-6"
        label="Название"
        v-model="formData.title"
      />

      <div class="popover">
        <p
          v-show="isShowPopover"
          class="mozzarella popover__content"
        >
          Введите данные или переместите пин на карте в нужное место
        </p>
        <r-input
          class="mt-6 pointer"
          label="Адрес или ориентир"
          v-model="formData.address"
          @onPressEnter="searchAddress"
          after-icon="search"
          :is-clear="false"
        />
      </div>

      <div class="mt-4 add-object__map">
        <rir-map :center="coords || $cityCenter">
          <ymap-marker
            :coords="coords || $cityCenter"
            :zoom="5"
            marker-id="marker1"
            :icon="$markerIcon()"
            :options="{ draggable: true }"
            @dragend="onDragMarker"
          />
        </rir-map>
      </div>
      <r-textarea
        class="mt-6"
        :rows="5"
        label="График работы"
        v-model="formData.workHours"
      />
      <r-textarea
        class="mt-6"
        :rows="5"
        label="Описание"
        v-model="formData.description"
      />
      <upload-file-input
        :count-file="1"
        class="mt-6"
        upload-url="/uploadfile/index.php"
        :value="files"
        :accept-file="['image/png', 'image/jpeg', 'image/gif']"
        @input="uploadFile"
        @input-file="file"
        sub-title="или перетяните сюда файл объёмом не более 50 МБайт"
      />

      <div class="flex add-object__contacts mt-6">
        <r-input
          class="flex-1 mr-4"
          label="Телефон объекта"
          v-model="formData.phone"
        />
        <r-input
          class="flex-1"
          label="E-mail объекта"
          v-model="formData.email"
        />
      </div>
      <r-input
        class="mt-6"
        label="Сайт"
        v-model="formData.web"
      />
      <r-checkbox
        class="mt-10 mr-1"
        style="float: left"
        v-model="isAcceptRules"
        title="Соглашаюсь с "
      />
      <div
        @click="publicOfferModal"
        class="rir-checkbox__label"
        style="margin-top: 36px;cursor: pointer; text-decoration: underline;opacity: 1;"
      >
        условиями и правилами
      </div>
      <r-button
        :disabled="!isFormValid || isLoading"
        class="mt-5"
        @click="send"
        title="Отправить на модерацию"
        width="wide"
      />
    </div>

    <div
      class="add-object__success"
      v-show="isSended"
    >
      <r-icon
        icon="selected"
        size="56"
        fill="matrix"
        style="margin: 0 auto;"
      />
      <h2 class="mt-8 camembert">
        Сообщение отправлено
      </h2>
      <p class="mt-4 parmigiano text-center">
        Информация о новом объекте отправлена на модерацию. В ближайшее время мы ознакомимся с ней и
        разместим на сайте.
      </p>
      <r-button
        type="secondary"
        class="mt-8"
        @click="$parent.closeModal()"
        title="Отлично"
        width="wide"
      />
    </div>
  </div>
</template>

<script>
import PlaceApi from '@/api/PlaceApi';
import RirMap from '@/components/RirMap';
import UploadFileInput from '@/components/UploadFileInput.vue';

export default {
  name: 'ModalAddObject',
  components: {
    RirMap,
    UploadFileInput
  },
  props: {
    authorName: {
      type: String,
      default: ''
    },
    authorEmail: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isShowPopover: false,
      description: null,
      files: [],
      formData: {
        title: null,
        address: null,
        phone: null,
        email: null,
        workHours: null,
        web: null,
        description: null,
        isShowAll: false
      },
      isAcceptRules: true,
      isSended: false,
      fileCountLines: 2,
      isLoading: false,
      coords: null,
      addressLoading: false,
      isShowMap: false,
      filesUpload: null
    };
  },
  computed: {
    isFormValid() {
      return this.isAcceptRules && this.formData.title?.length > 2;
    }
  },
  created() {
    window.addEventListener('resize', this.onWindowResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  mounted() {
    this.onWindowResize();
  },
  activated() {
    this.isShowMap = true;
  },
  deactivated() {
    this.isShowMap = false;
  },
  methods: {
    formattedFiles(files) {
      const _this = this;
      const formattedFiles = [];
      console.log(files);
      files.forEach(el => {
        if (el?.files?.length) {
          const fileList = el.files.map(el => ({
            type: el.type,
            name: el.name, // .split('.')[0]
            size: el?.size,
            icon: false,
            url: el.url
          }));
          _this.files.push(...fileList);
        } else {
          _this.files.push(el);
        }
      });
    },
    publicOfferModal() {
      /* window.dispatchEvent(new CustomEvent('openModal', {
        detail: {
          name: 'publicOfferModal',
        },
      })) */
      window.open('/privacy', '_blank').focus();
    },
    async searchAddress(address = this.formData.address) {
      if (!address) return;
      const api = new PlaceApi();

      const data = {};
      const isFindAddress = Array.isArray(address);

      if (isFindAddress) {
        data.lat = address[0];
        data.lng = address[1];
      } else {
        data.address = this.formData.address;
      }

      this.addressLoading = true;

      const res = await api.getAddress(JSON.stringify(data));

      if (isFindAddress && res?.address) {
        this.formData.address = res.address;
      } else if (!isFindAddress && res?.lat && res?.lng) {
        this.coords = [res.lat, res.lng];
      }

      this.addressLoading = false;
    },

    onWindowResize() {
      const width = window.innerWidth;
      this.fileCountLines = width < 600 ? 1 : 2;
    },
    uploadFile(files) {
      const _this = this;
      if (!files?.length) {
        _this.files = [];
        return;
      }
      _this.formattedFiles(files);
    },
    file(files) {
      this.files = [];
      this.formattedFiles(files);
    },
    async send() {
      this.isLoading = true;
      const api = new PlaceApi();

      const images = this.files.map(file => ({ filename: file.filename, url: file.url }));
      const data = {
        name: this.formData.title,
        descr: this.formData.description,
        address: this.formData.address,
        author: this.authorName,
        authorEmail: this.authorEmail,
        phones: this.formData.phone ? [this.formData.phone] : null,
        emails: this.formData.email ? [this.formData.email] : null,
        web: this.formData.web,
        authorPublic: this.formData.isShowAll ? '1' : '0',
        schedule: this.formData.workHours,
        coords: this.coords,
        images
      };

      const res = await api.setItem(JSON.stringify(data));
      this.isLoading = false;
      if (res?.error === '') {
        this.isSended = !this.isSended;
      }
    },

    onDragMarker(e) {
      const newCoords = e.originalEvent.target.geometry._bounds[0];
      this.coords = newCoords;
      this.searchAddress(this.coords);
    }
  }
};
</script>

<style lang="scss" scoped>
.add-object {
  &__formData {
    text-align: left;
    max-width: 618px;
    margin: 0 auto;
  }
  &__map {
    height: 200px;
    background-color: #eee;
  }
  &__success {
    z-index: 5;
    position: absolute;
    top: calc(50% - 164px);
    max-width: 624px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
  }
  &__contacts {
    .rir-input:last-child {
      margin-left: 16px;
    }
  }
}

.popover {
  position: relative;
}
.popover__content {
  position: absolute;
  right: 6px;
  bottom: calc(100% + 6px);
  max-width: 240px;
  padding: 4px 8px;
  border-radius: 12px;
  background-color: #fff;
  -webkit-filter: drop-shadow(0px 1px 2px #ccc);
  filter: drop-shadow(0px 1px 2px #ccc);
  z-index: 100;
  &::before {
    content: '';
    width: 12px;
    height: 6px;
    background-color: #fff;
    position: absolute;
    right: 12px;
    bottom: -5px;
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  }
}

::v-deep .rir-file-list__details {
  padding: 6px;
}
::v-deep .rir-file-list__item .rir-file-list__img {
  min-width: 76px;
  width: 76px;
  height: 100%;
}

::v-deep .rir-file-list__item.mini .rir-file-list__info {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.72;
}

::v-deep .rir-file-list__info {
  color: var(--rir-titanic);
  margin-bottom: 0;
}
::v-deep .rir-file-list__size {
  font-size: 13px;
  line-height: 16px;
  color: var(--rir-rocky);
}
::v-deep .rir-drag-upload-file__remove {
  & svg {
    & path {
      fill: var(--rir-fargo);
    }
  }
}

::v-deep .rir-modal__content-rir-icon_close {
  z-index: 1001;
}

@media screen and (max-width: 1023px) {
  .add-object {
    &__formData {
      max-width: 350px;
    }
  }
}

@media screen and (max-width: 599px) {
  .add-object {
    &__formData {
      max-width: 272px;
    }
    &__contacts {
      flex-direction: column;
      .rir-input:last-child {
        margin-top: 24px;
        margin-left: unset;
      }
    }
  }
}
</style>
<style>
.RModal__content {
  max-width: unset !important;
  z-index: 5;
}
</style>
