<template>
  <div class="card">
    <div class="card__img-cont">
      <img
        class="card__img"
        loading="lazy"
        v-if="img"
        :src="img"
        :alt="name"
      >
      <r-icon
        v-else
        icon="mayor"
        size="32"
        fill="rocky"
      />
    </div>
    <div class="card__info">
      <h3 class="card__title">
        {{ name }}
      </h3>
      <p class="card__address">
        {{ address }}
      </p>
      <div
        v-if="$slots.default"
        class="card__additional"
      >
        <r-icon
          v-if="icon"
          :icon="icon"
          fill="rocky"
          size="16"
        />
        <div class="card__additional-info">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['name', 'address', 'img', 'icon']
};
</script>

<style lang="scss" scoped>
.card {
  width: 90%;
  display: flex;
  margin-bottom: 32px;
  &:hover {
    * {
      color: var(--rir-godfather);
    }
  }
  &__img-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 56px;
    min-height: 56px;
    width: 56px;
    height: 56px;
    border-radius: 8px;
    margin-right: 16px;
    background-color: var(--rir-arrival);
    overflow: hidden;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: left;
    overflow: hidden;
  }
  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    color: var(--rir-titanic);
  }
  &__address {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: var(--rir-titanic);
    opacity: 0.72;
  }
  &__additional {
    margin-top: 12px;
    display: flex;
    align-items: center;
  }
  &__additional-info > * {
    margin-left: 6px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: var(--rir-rocky);
  }
}
</style>
