import ApiService from './ApiService';

export default class PlaceApi extends ApiService {
  async getTypes(payload) {
    const { data } = await this._axios.post('ajax.php?action=getPlacesTypes', {
      params: payload
    });
    return data;
  }

  async getList(payload) {
    const { data } = await this._axios.post('ajax.php?action=getPlaces&v=2', payload);
    // const { data } = await this._axios.post('ajax.php?action=getPlaces&wHidden=1');
    return data;
  }

  async getFavorite(payload) {
    const { data } = await this._axios.post('ajax.php?action=operateFavorite', {
      currentMainMenuNumber: 74
    });
    return data;
  }

  async setFavorite(id, title) {
    const { data } = await this._axios.post('ajax.php?action=operateFavorite', {
      currentMainMenuNumber: 74,
      id,
      title
    });
    return data;
  }

  async getTransport(payload) {
    const { lat, lng } = payload;
    const { data } = await this._axios.get(
      `transportProxy.php?url=stations&lat=${lat}&lng=${lng}&radius=500`
    );
    return data;
  }

  async setItem(payload) {
    const { data } = await this._axios.post('ajax.php?action=addNewPlace', payload);
    return data;
  }

  async setRating(payload) {
    const { data } = await this._axios.post('ajax.php?action=operatePublicRating', payload);
    return data;
  }

  async getUserInfo() {
    const { data } = await this._axios.get('nginxApi.php?get=user');
    return data;
  }

  async getAddress(payload) {
    const { data } = await this._axios.post('mob_ajax.php?action=geosearch', payload);
    return data;
  }
}
